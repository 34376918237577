import Slider, { Settings } from 'react-slick';

const SliderOrDiv: React.FC<Settings & { isSlider: boolean; className?: string }> = ({
  children,
  isSlider,
  className,
  ...rest
}) => {
  return isSlider ? (
    <Slider className={className || ''} {...rest}>
      {children}
    </Slider>
  ) : (
    <div className={className || ''}>{children}</div>
  );
};

export default SliderOrDiv;
