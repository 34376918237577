import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { FiPlus, FiMinus } from 'react-icons/fi';

const AccordionFaq: React.FC<{ className?: string; toggleClassName?: string; keyName: string; title?: string; renderTitle?: () => JSX.Element, openIcon?: JSX.Element, hideIcon?: JSX.Element, defaultShow?: boolean, disabled?: boolean }> = ({
  className,
  toggleClassName,
  keyName,
  title,
  children,
  renderTitle,
  defaultShow,
  disabled,
  hideIcon = <FiMinus size={24} className="tw-text-brand-navy" />,
  openIcon = <FiPlus size={24} className="tw-text-brand-navy" />
}) => {
  const [activeKey, setActiveKey] = useState<string | undefined>(defaultShow ? keyName : undefined);
  return (
    <Accordion activeKey={activeKey}>
      <div className={`${className || 'tw-border-b tw-border-grey-lighter tw-border-solid tw-py-5'}`}>
        <Accordion.Toggle
          className={toggleClassName || 'tw-flex tw-cursor-pointer tw-items-center'}
          as="div"
          eventKey={disabled ? `${keyName}disabled` : keyName}
          onClick={() => {
            if (disabled) {
            } else {
              setActiveKey((prev) => (prev ? undefined : keyName));
            }
          }}
        >
          {renderTitle ? renderTitle() : (
            <span className="tw-font-semibold tw-font-styleSans tw-mr-4">{title}</span>
          )}
          {!disabled ? (
            <span className="tw-ml-auto">
              {activeKey ? hideIcon : openIcon}
            </span>
          ) : null}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={keyName} className="tw-mt-4">
          <>{children}</>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default AccordionFaq
